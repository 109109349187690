import * as React from "react";
import {
  TemplateList,
  TemplateShow,
  TemplateCreate,
  TemplateEdit,
} from "./templates";
import { FeedBackEdit, FeedBackList, FeedBackShow } from "./projects";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import CustomLoginPage from "./CustomLoginPage";
import "./App.css";

import { firebaseConfig as config } from "./FIREBASE_CONFIG";
import { UsersEdit, UsersList } from "./users";

const options = {
  logging: true,
  rootRef: "/",
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

class App extends React.Component {
  render() {
    return (
      <Admin
        loginPage={CustomLoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="templates"
          list={TemplateList}
          show={TemplateShow}
          create={TemplateCreate}
          edit={TemplateEdit}
        />
        <Resource
          name="projects"
          list={FeedBackList}
          show={FeedBackShow}
          // create={FeedBackCreate}
          edit={FeedBackEdit}
        />
        <Resource
          name="users"
          list={UsersList}
          edit={UsersEdit}
        />
      </Admin>
    );
  }
}

export default App;
