// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  BooleanInput,
  DateField,
  BooleanField,
  UrlField,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const TemplateList = (props) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="description" />
      {/* <RichTextField source="body" /> */}
      <DateField source="createdate" />
      <BooleanField label="Publish" source="is_active" />
      <TextField source="brd_url" />
      <TextField label="BRD template ID" source="brd_template_id" />
      <TextField source="prd_url" />
      <TextField label="PRD template ID" source="prd_template_id" />
      {/* <TextField source="lastupdate" /> */}
      <BooleanField label="Dynamic Form" source="is_dynamic" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const TemplateShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField label="Publish" source="is_active" />
      <BooleanField label="Dynamic Form" source="is_dynamic" />
      <TextField source="name" />
      <TextField source="description" />
      <RichTextField source="json_schema" />
      <RichTextField source="ui_schema" />
      <UrlField source="brd_url" />
      <UrlField label="BRD template ID" source="brd_template_id" />
      <UrlField source="prd_url" />
      <UrlField label="PRD template ID" source="prd_template_id" />
      <UrlField source="flow_url" />
      <UrlField label="Flow template ID" source="flow_template_id" />
      <UrlField source="wireframe_url" />
      <UrlField source="question_url" />
      <UrlField label="Wireframe template ID" source="wireframe_template_id" />
      {/* <ReferenceField label="Comment" source="title" reference="comments">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="createdate" />
      <TextField source="lastupdate" />
      <RichTextField source="body" />
      <FileField source="file.src" title="file.title" /> */}
    </SimpleShowLayout>
  </Show>
);

export const TemplateCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Active" source="is_active" />
      <BooleanInput label="Dynamic Form" source="is_dynamic" />
      <TextInput source="name" />
      <TextInput source="description" />
      <RichTextInput source="json_schema" />
      <RichTextInput source="ui_schema" />
      {/* <TextInput source="flowise_url" /> */}
      <TextInput source="brd_url" />
      <TextInput label="BRD template ID" source="brd_template_id" />
      <TextInput source="prd_url" />
      <TextInput label="PRD template ID" source="prd_template_id" />
      <TextInput source="flow_url" />
      <TextInput label="Flow template ID" source="flow_template_id" />
      <TextInput source="wireframe_url" />
      <TextInput source="question_url" />
      <TextInput label="Wireframe template ID" source="wireframe_template_id" />
      {/* <FileInput source="file" label="File" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput> */}
    </SimpleForm>
  </Create>
);

export const TemplateEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput label="Publish" source="is_active" />
      <BooleanInput label="Dynamic Form" source="is_dynamic" />
      <TextInput source="name" />
      <TextInput source="description" />
      <RichTextInput source="json_schema" />
      <RichTextInput source="ui_schema" />
      <TextInput source="brd_url" />
      <TextInput label="BRD template ID" source="brd_template_id" />
      <TextInput source="prd_url" />
      <TextInput label="PRD template ID" source="prd_template_id" />
      <TextInput source="flow_url" />
      <TextInput label="Flow template ID" source="flow_template_id" />
      <TextInput source="wireframe_url" />
      <TextInput source="question_url" />
      <TextInput label="Wireframe template ID" source="wireframe_template_id" />
    </SimpleForm>
  </Edit>
);
