import { signInWithPopup } from "firebase/auth";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, googleProvider } from "./config.js";
import { usersRef } from "./collection.js";

export const handleError = (error) => {
  console.error(error?.message);
};

export const fetchData = async (ref) => {
  try {
    const data = await getDocs(ref);
    let firebaseData = data?.docs?.map((doc) => ({
      ...doc.data(),
      id: doc?.id,
    }));
    return firebaseData;
  } catch (error) {
    handleError(error);
  }
};

export const addData = async (ref, payload) => {
  try {
    const docRef = await addDoc(ref, payload);
    return docRef?.id;
  } catch (error) {
    handleError(error);
  }
};

export const fetchSingleData = async (table, id) => {
  try {
    const docSnap = await getDoc(doc(db, table, id));
    return docSnap?.data();
  } catch (error) {
    handleError(error);
  }
};

export const updateData = async (ref, table, id, payload) => {
  try {
    const prevData = await fetchSingleData(table, id);
    await setDoc(doc(ref, id), { ...prevData, ...payload });
    return true;
  } catch (error) {
    handleError(error);
  }
};

export const fetchDataWithWhere = async (table, id, condition) => {
  try {
    const data = await getDocs(query(table, condition));
    let firebaseData = data?.docs?.map((doc) => ({
      ...doc?.data(),
      id: doc?.id,
    }));
    return firebaseData;
  } catch (error) {
    handleError(error);
  }
};

export const handleLogin = async () => {
  try {
    const data = await signInWithPopup(auth, googleProvider);
    if (data?.user) {
      const { displayName, email, uid, photoURL, accessToken } = data?.user;

      const fetchData = await fetchDataWithWhere(
        usersRef,
        uid,
        where("uid", "==", uid)
      );

      if (fetchData?.length === 0) {
        await addData(usersRef, {
          displayName,
          email,
          uid,
          photoURL,
          isVerified: false,
          access: false,
          createdAt: new Date()?.toISOString(),
        });
      }
    }
  } catch (error) {
    handleError(error);
  }
};
