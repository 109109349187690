// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  DeleteButton,
  BooleanInput,
  ChipField,
  FunctionField,
  EditButton,
} from "react-admin";

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

export const FeedBackList = (props) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="project_name" />
      <FunctionField
            label="Project Status"
            render={({ is_active }) => (is_active ? <p>Active</p> : <p>Inactive</p>)}
          />
      {/* <TextField source="form_data" /> */}
      <ChipField source="status" />
      {/* <RichTextField source="body" /> */}
      {/* <TextField source="template_id" /> */}
      <FunctionField
        label="Template ID"
        render={({ template_id }) => (
          <a
            href={`/templates/${template_id}/show#/templates/${template_id}/show`}
          >
            {template_id}
          </a>
        )}
      />
      <FunctionField
        label="Template Name"
        render={({ template_id, template_name }) => (
          <a
            href={`/templates/${template_id}/show#/templates/${template_id}/show`}
          >
            {template_name}
          </a>
        )}
      />
      {/* <TextField source="lastupdate" /> */}
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const FeedBackShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="project_name" />
        <FunctionField
          label="Form Data"
          render={(record) => {
            if (record?.form_data) {
              let parsedData = JSON?.parse(record?.form_data);
              let data = Object?.entries(parsedData)?.map((item) => (
                <>
                  <p>{item?.[0]}</p>
                  <p>
                    Answer:
                    {!Array?.isArray(item?.[1])
                      ? item?.[1]
                      : item?.[1]?.map((d) => d?.name)?.join(", ")}
                  </p>
                </>
              ));
              return data;
            }
            return "No data";
          }}
        />
        <ChipField source="status" />
      </SimpleShowLayout>
    </Show>
  );
};

export const FeedBackCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Active" source="is_active" />
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="json_schema" />
      <TextInput source="ui_schema" />
      {/* <FileInput source="file" label="File" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput> */}
    </SimpleForm>
  </Create>
);

export const FeedBackEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput label="Active" source="is_active" />
      </SimpleForm>
    </Edit>
  );
};
