// in src/posts.js
import * as React from "react";
import { useEffect, useState } from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  DeleteButton,
  BooleanInput,
  ChipField,
  FunctionField,
  DateField,
  EditButton,
  ListButton,
  AutocompleteArrayInput,
  CheckboxGroupInput,
} from "react-admin";
import { projectsRef } from "./firebase/collection";
import { fetchData } from "./firebase/api";

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

export const UsersList = (props) => {
  return (
    <>
      <List {...props} filters={<PostFilter />}>
        <Datagrid>
          <TextField source="displayName" />
          <TextField source="email" />
          <FunctionField
            label="Request"
            render={({ access }) => (access ? <p>REQUESTED</p> : <p>-</p>)}
          />
          <FunctionField
            label="Permission"
            render={({ isVerified }) =>
              isVerified ? <p>ALLOWED</p> : <p>-</p>
            }
          />
          <DateField source="createdAt" label="CreatedAt (MM-DD-YYYY)" />
          <FunctionField
            label="Profile"
            render={({ displayName, photoURL }) => {
              return (
                <img
                  src={photoURL}
                  width={50}
                  className="user-profile"
                  alt={displayName}
                />
              );
            }}
          />
          {/* <ChipField source="status" /> */}
          {/* <ShowButton label="" /> */}
          <EditButton label="" redirect={false} />
          <DeleteButton label="" redirect={false} />
        </Datagrid>
      </List>
    </>
  );
};

export const UsersEdit = (props) => {
  const [projectList, setProjectList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchProjectList = async () => {
      const data = await fetchData(projectsRef);
      setProjectList(
        data?.map(({ id, project_name }) => {
          return { id: id, name: project_name };
        })
      );
    };
    fetchProjectList();
  }, []);

  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput label="Permission" source="isVerified" />
        <div className="project-assign-wrapper">
          <CheckboxGroupInput
            source="projects_assigned"
            choices={projectList || []}
          />
        </div>
      </SimpleForm>
    </Edit>
  );
};
